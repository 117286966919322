import moment from 'moment';
import { actions } from "../../../components/grid-views/standart-actions-without-view";
import { statusActions } from "../../../components/grid-views/status-actions";
import {APIService} from "../../../services/api";
import i18n from "@/plugins/i18n";

export const columns = [
  {
    label: 'code',
    field: 'code',
    width: '31%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'translation',
    field: 'translation',
    width: '31%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'language',
    field: 'language',
    width: '12%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'module',
    field: 'module',
    width: '16%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: '',
    field: 'actions',
    width: '10%',
    sortable: false,
    actions: [
      ...statusActions,
      ...actions,
    ]
  },
]
